<template>
  <a-modal :title="modalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px" :zIndex='4100'
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form" layout="vertical">
        <a-row type="flex" align="top" :gutter="16">
            <a-col :span="item.type === 'textarea'?24:12" v-for="(item, index) of formData" :key="index" >
                <a-form-item v-if="item.type !== 'textarea' && item.type !== 'promise_date_time'" :label="item.label">
                    <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
                    <a-input-number style='width:100%' v-if="item.type === 'input-number'" :min='0' v-decorator="[item.name, { rules: item.rules }]"/>
                    <a-date-picker v-if="item.type === 'date'" style="width: 100%" :popupStyle='{zIndex:4200}'  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
                    <a-select v-else-if="item.type === 'select'" placeholder="请选择" :dropdownStyle='{zIndex:4200}'
                    v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption" allowClear>
                    <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
                    </a-select>
                    <a-select v-else-if="item.type === 'selects'" placeholder="请输入客户名称" :dropdownStyle='{zIndex:4200}' allowClear v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption" @search="handleSearch">
                      <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
                    </a-select>
                    <a-cascader
                    v-else-if="item.type === 'cascader'"
                    :options="seleteItems[item.items.data]"
                    v-decorator="[item.name, { rules: item.rules }]"
                    :show-search="{ filterOption }"
                    placeholder="请选择"
                    :popupStyle='{zIndex:4200}'
                    @change="onChangeCascader">
                        <template slot="displayRender" slot-scope="{labels}">
                            <span>{{labels[1]}}</span>
                        </template>
                    </a-cascader>
                    <a-radio-group v-else-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                    <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
                    </a-radio-group >
                </a-form-item>
                <!-- <a-form-item v-if="item.type === 'promise_date_time' && isEdit === 0" :label="item.label">
                  <a-date-picker v-if="item.type === 'promise_date_time' && isEdit === 0" style="width: 100%" :popupStyle='{zIndex:4200}'  v-decorator="[item.name, { rules: item.rules }]" :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm"/>
                </a-form-item> -->
                <a-form-item v-if="item.type === 'textarea'" :label="item.label">
                  <a-textarea :rows="4" v-decorator="[item.name, { rules: item.rules }]"/>
                </a-form-item>
            </a-col>
        </a-row>
      
    </a-form>
    
  </a-modal>
</template>

<script>
import editModal from '@/common/mixins/editModal'
import C_ITEMS from '@/utils/items'
import moment from 'moment'

function phoneCheck (rule, value, callbackFn) {
          const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
          if (!reg.test(value)) {
            callbackFn('请输入正确手机号码!')
            return
          }
          callbackFn()
        }

const formData = [
  {
    name: 'name',
    label: '客户名称',
    type: 'input',
    rules: [{ required: true, message: '请输入客户名称!' }]
  },
  {
    name: 'cellphone',
    label: '联系电话',
    type: 'input',
    rules: [{ required: true, message: ' ' ,whitespace: true},{validator:phoneCheck.bind(this)}],
  },
  {
    name: 'gender',
    label: '客户性别',
    type: 'radio',
    rules: [],
    items: {
      data: 'genderList',
      label: 'gender_name',
      value: 'gender_id'
    }
  },
  {
    name: 'studio_id',
    label: '所属校区',
    type: 'select',
    rules: [{ required: true, message: '请选择所属校区' }],
    items: {
      data: 'studios',
      label: 'filter_name',
      value: 'studio_id'
    }
  },
   {
    name: 'course_cate_id',
    label: '意向课程',
    type: 'select',
    rules: [],
    items: {
      data: 'classCategorys',
      label: 'filter_name',
      value: 'course_cate_id'
    }
  },
  {
    name: 'channel_id',
    label: '来源渠道',
    type: 'cascader',
    rules: [{ required: true, message: '请选择来源渠道!' }],
    items: {
      data: 'channels',
      label: 'filter_name',
      value: 'source_channel_id'
    }
  },
  {
    name: 'course_consultant_id',
    label: '课程顾问',
    type: 'select',
    rules: [{ required: true, message: '请选择课程顾问!' }],
    items: {
      data: 'consultants',
      label: 'filter_name',
      value: 'course_consultant_id'
    }
  },
  {
    name: 'birthday',
    label: '出生日期',
    type: 'date',
    rules: [],
  },
  {
    name: 'school',
    label: '在读学校',
    type: 'input',
    rules: [],
  },
  {
    name: 'year',
    label: '学员年龄',
    type: 'input-number',
    rules: []
  },
  {
    name: 'referer_customer_id',
    label: '推荐人',
    type: 'selects',
    rules: [],
    items: {
      data: 'customerList',
      label: 'customer_name',
      value: 'customer_id'
    }
  },
  {
    name: 'remark',
    label: '客户备注',
    type: 'textarea',
    rules: [],
  }
  // {
  //   name: 'grade',
  //   label: '在读年级',
  //   type: 'select',
  //   rules: [],
  //   items: {
  //     data: 'grades',
  //     label: 'filter_name',
  //     value: 'value'
  //   }
  // },
  // {
  //   name: 'promise_date_time',
  //   label: '诺访时间',
  //   type: 'promise_date_time',
  //   rules: [],
  //   isShow:1
  // },
]




export default {
  name: 'EditModal',
  mixins: [ editModal ],
  data() {
    return {
      loading:false,
      formData,
      seleteItems: {
        studios: [],
        channels:[],
        genderList:[{gender_name:'男',gender_id:1},{gender_name:'女',gender_id:2},{gender_name:'未知',gender_id:0}],
        consultants:[],
        classCategorys:[],
        customerList:[]
      },
      channelsId:[],
      modalTitle:''
    }
  },

  created() {
      this.visible = true
      this.getConsultant()
      this.getStudio()
      this.getSearchCustomer()
      // this.getGrade()
      this.getClassCategory()
  },
  async mounted(){
      let msg = this.item
      await this.$nextTick()
      if(this.item){
        this.modalTitle = '编辑客户'
        this.form.setFieldsValue({
          name:msg.customer_detail.name,
          cellphone:msg.customer_detail.cellphone,
          studio_id:msg.customer_detail.studio_id,
          school:msg.customer_detail.school,
          gender:msg.customer_detail.gender || 0,
          // grade:msg.customer_detail.grade,
          year:msg.customer_detail.year,
          course_cate_id:msg.customer_detail.course_cate_id,
          course_consultant_id:msg.customer_detail.course_consultant_id,
          remark:msg.customer_detail.remark,
        })
        if(msg.customer_detail.referer_customer_id){
          this.seleteItems.customerList.push(msg.customer_detail.referer_customer_id)
          this.form.setFieldsValue({referer_customer_id:msg.customer_detail.referer_customer_id.customer_id})
        }
        if(msg.customer_detail.birthday){
          this.form.setFieldsValue({birthday:moment(msg.customer_detail.birthday)})
        }
        this.getSourceChannel(msg.customer_detail.channel_id)
      }else{
        this.modalTitle = '新增客户'
        this.getSourceChannel()
        this.form.setFieldsValue({gender:1,course_consultant_id:this.$store.getters.employee_id})
      }
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getGrade(){
      let res = await this.$store.dispatch('searchGetGradeTypeAction', {})
        this.seleteItems.grades = res.data
    },
    async getStudio() {
        let res = await this.$store.dispatch('searchBelongStudioAction', {})
        this.seleteItems.studios = res.data
    },
    async getSourceChannel(id) {
      this.loading = true
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', { })
      this.loading = false
      this.seleteItems.channels = res.data[0].children
      if(id){
        res.data[0].children.forEach(item=>{
          item.children.forEach(it=>{
            if(it.value == id){
              this.channelsId = [item.value,it.value]
              this.form.setFieldsValue({channel_id:this.channelsId})
            }
          })
        })
      }
    },
    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.seleteItems.consultants = res.data
    },
    async getSearchCustomer(val) {
      let res = await this.$store.dispatch('searchCustomerAction', {params:{q:val}})
      this.seleteItems.customerList = res.data
    },
    async getClassCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.seleteItems.classCategorys = res.data
    },
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if(!(/^1[3456789]\d{9}$/.test(params.cellphone))){
          this.$message.warning('请输入正确的手机号!')
          return false
        }
        this.confirmLoading=true
        params.channel_id = this.channelsId[1]
        if(params.birthday){
          params.birthday = moment(params.birthday).format('YYYY-MM-DD')
        }
        // if(params.promise_date_time){
        //   params.promise_date_time = moment(params.promise_date_time).format('YYYY-MM-DD HH:mm')
        // }
        if (params) {
          if (item) {
            params.task_id = item.task_id
          }
          let res = await this.$store.dispatch(item ? 'cCustomerUpdateAction' : 'cCustomerAddAction', params).then(res=>{
            this.confirmLoading=false
            this.$message.success('操作成功!')
            form.resetFields()
            this.parent.hideEditModal(1)
          }).catch(err=>{
            this.confirmLoading=false
          })
          
        }
      } catch {
      }
      
    },
    handleCancel(){
      this.parent.hideEditModal()
    },
    onChangeCascader(value, selectedOptions) {
      this.channelsId = value;
    },
    handleSearch(val){
      this.getSearchCustomer(val)
    },

  }
}
</script>