<template>
    <div @click="hideFollowModal" style="height:100%">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生咨询</a-breadcrumb-item>
            <a-breadcrumb-item>客户流失</a-breadcrumb-item>
        </a-breadcrumb>
        <div @click.stop class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                    <!-- <a-form-item v-if="canSave">
                        <a-button @click='showEditModal' type="primary" icon="plus" >新增</a-button>
                    </a-form-item>
                    <a-form-item v-if="canExcelimport">
                        <a-button @click="showUploadModal" icon="export">导入</a-button>
                    </a-form-item> -->
                    <!-- <a-form-item v-if="canExcelexport">
                        <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-dropdown :visible="showColumns">
                            <a-menu @mouseleave="showColumns = false" slot="overlay">
                                <a-menu-item :key="index" v-for="(item,index) in columns">
                                    <a-checkbox @change="onChangeColspan(item)" :checked="item.show_colspan">{{item.title}}</a-checkbox>
                                </a-menu-item>
                            </a-menu>
                            <a-button @click="showColumns = true"> 选择列 <a-icon type="down" /> </a-button>
                        </a-dropdown>
                    </a-form-item> -->
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline' v-if="selectedRowKeys.length == 0" @submit="searchList">
                        <a-form-item>
                            <a-radio-group :value="searchParams.search.is_star">
                                <a-radio-button :value="1" @click="handleChangeRadioStar(1)">
                                    <a-tooltip>
                                        <template slot="title">
                                        只看关注
                                        </template>
                                        <a-icon v-if="searchParams.search.is_star" type="star" theme="twoTone" two-tone-color="#f6d553" />
                                        <a-icon v-else type="star" theme="twoTone" two-tone-color="#d9d9d9" />
                                    </a-tooltip>
                                </a-radio-button>
                            </a-radio-group>
                        </a-form-item>
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                                <a-select-option :value="0">我的</a-select-option>
                                <a-select-option :value="1">我下属的</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-input v-model='searchParams.search.keyword'  style="width: 160px" allowClear placeholder="客户名称/手机号"></a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-select v-model='searchParams.search.studio_id' style="width: 200px" mode="multiple" :maxTagCount="1" :maxTagTextLength='5'  allowClear showSearch placeholder="请选择校区" :filterOption="filterOption">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <!-- <a-form-item>
                            <a-input v-model='searchParams.search.cellphone'   style="width: 140px" allowClear placeholder="联系电话"></a-input>
                        </a-form-item> -->
                        <a-form-item v-if="canIndex">
                            <a-button @click="searchList" html-type="submit" :loading="loading" type="primary">搜索</a-button>
                        </a-form-item>
                        <a-form-item>
                            <a-popover placement="bottom" trigger="click" v-model="visible">
                            <template slot="content">
                                <div class="more-search" style="width:740px">
                                <a-form layout='inline'>
                                    <a-form-item class="block-line" label="来源渠道">
                                        <a-tree-select
                                            v-model="searchParams.search.channel_id"
                                            :tree-data="channels"
                                            tree-checkable
                                            allowClear
                                            treeNodeFilterProp="title"
                                            :maxTagCount='1'
                                            :maxTagTextLength='5'
                                            :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                                            :show-checked-strategy="SHOW_ALL"
                                            placeholder="请选择来源渠道"
                                            @change="changeBadge"
                                        />
                                    </a-form-item>
                                    <a-form-item class="block-line" label="意向科目">
                                        <a-select v-model='searchParams.search.course_cate_id' @change="changeBadge" allowClear showSearch placeholder="请选择意向科目" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of classCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="意向程度">
                                        <a-select v-model='searchParams.search.intention_level' @change="changeBadge" allowClear placeholder="请选择意向程度">
                                        <a-select-option v-for="(d, index) of intentionLevels" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="学员类型">
                                        <a-select v-model='searchParams.search.deal_status' @change="changeBadge" allowClear placeholder="请选择学员类型">
                                        <a-select-option v-for="(d, index) of studentTypes" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="跟进状态">
                                        <a-select mode="multiple" v-model='searchParams.search.task_step' @change="changeBadge" allowClear placeholder="请选择跟进状态">
                                        <a-select-option v-for="(d, index) of dealStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="到访状态">
                                        <a-select v-model='searchParams.search.is_attend' @change="changeBadge" allowClear placeholder="请选择到访状态">
                                        <a-select-option v-for="(d, index) of isAttends" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="课程顾问">
                                        <a-select mode="multiple" v-model='searchParams.search.course_consultant_id' @change="changeBadge" allowClear showSearch placeholder="请选择课程顾问" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="添加人">
                                        <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='searchParams.search.created_by' @change="changeBadge" allowClear showSearch @search="handleSearch" placeholder="请选择添加人" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <!-- <a-form-item class="block-line" label="在读年级">
                                        <a-select mode="multiple" v-model='searchParams.search.grade' allowClear showSearch placeholder="请选择在读年级" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of grades" :key="index" :value="d['value']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item> -->
                                    <a-form-item class="block-line" label="诺访时间">
                                            <a-range-picker
                                            :ranges="rangesData"
                                            v-model='searchDatas.promise'
                                            allowClear
                                            @change="(val,time)=>handleTime(val,time,'promise')" />
                                    </a-form-item>
                                    <a-form-item class="block-line" label="回访时间">
                                            <a-range-picker
                                            :ranges="rangesData"
                                            v-model='searchDatas.next'
                                            allowClear
                                            @change="(val,time)=>handleTime(val,time,'next')" />
                                    </a-form-item>
                                    <a-form-item class="block-line" label="分配时间">
                                            <a-range-picker
                                            :ranges="rangesData"
                                            v-model='searchDatas.distribute'
                                            allowClear
                                            @change="(val,time)=>handleTime(val,time,'distribute')" />
                                    </a-form-item>

                                    <a-form-item class="block-line" label="到访时间">
                                            <a-range-picker
                                            :ranges="rangesData"
                                            v-model='searchDatas.attend'
                                            allowClear
                                            @change="(val,time)=>handleTime(val,time,'attend')" />
                                    </a-form-item>
                                    <a-form-item class="block-line" label="最后跟进">
                                            <a-range-picker
                                            :ranges="rangesData"
                                            v-model='searchDatas.update'
                                            allowClear
                                            @change="(val,time)=>handleTime(val,time,'update')" />
                                    </a-form-item>
                                    <a-form-item class="block-line" label="转化时间">
                                            <a-range-picker
                                            :ranges="rangesData"
                                            v-model='searchDatas.add'
                                            allowClear
                                            @change="(val,time)=>handleTime(val,time,'add')" />
                                    </a-form-item>
                                    <!-- <a-form-item class="block-line" label="剩余费用">
                                            <a-input-group compact>
                                                <a-select style="width:100px" v-model="customer_optionValue" @change="onChangeCostWarn">
                                                    <a-select-option v-for="(item,index) in customer_optionList" :key="index" :value="item.value">{{item.label}}</a-select-option>
                                                </a-select>
                                                <a-input-number style="width:140px" v-model="customer_number" :min="0" @change="onChangeCostWarn" />
                                            </a-input-group>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="跟进事件">
                                        <a-select v-model="searchParams.search.event_id" placeholder="请选择事件" @change="changeBadge" allowClear showSearch :filterOption="filterOption">
                                            <a-select-option v-for="(item,index) in eventList" :key="index" :value="item.event_id">{{item.event_name}}</a-select-option>
                                        </a-select>
                                    </a-form-item> -->

                                </a-form>
                                <div class="more-search__buttons">
                                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                                    <a-button @click="reset">重置</a-button>
                                    <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                                </div>
                                </div>
                            </template>
                            <a-badge :count="badgeCount">
                                <a-button>更多<a-icon type="down" /></a-button>
                            </a-badge>
                            </a-popover>
                        </a-form-item>
                    </a-form>
                    <a-form layout='inline' v-else>
                        <a-form-item>
                            已选择<span>{{selectedRowKeys.length}}</span>项
                        </a-form-item>
                        <a-form-item>
                            <a-divider type="vertical" />
                        </a-form-item>
                        <a-form-item v-if="canTransfer">
                            <a-button @click="showTransfer" icon="apartment">批量转移</a-button>
                        </a-form-item>
                        <a-form-item v-if="canTransfer">
                            <a-button @click="showAuditionModal" icon="apartment">试听排课</a-button>
                        </a-form-item>
                        <a-form-item v-if="canTransfer">
                            <a-button @click="toPool" icon="apartment">放入公海</a-button>
                        </a-form-item>
                        <a-form-item v-if="canTransfer">
                            <a-button @click="toRenew" icon="vertical-align-top">RENEW</a-button>
                        </a-form-item>

                        <a-form-item v-if="canTransfer">
                            <a-button @click="toEvent" icon="flag">事件跟踪</a-button>
                        </a-form-item>

                        <a-form-item v-if="canDelete">
                            <a-button @click="toDel" icon="delete">批量删除</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="middle" :pagination="false" :bordered='false' rowKey="task_id"
                    :selection="{key: 'key'}"
                    :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                    :columns="columnsList" :dataSource="list" :customRow="clickRow" :rowClassName="rowClassName" :scroll="{ x: 1300 }"  @change="handleChange" >
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>

                    <template slot="star" slot-scope="text, record">
                        <a-icon @click.stop="toTop(record)" v-if="record.is_star == 1" type="star" theme="twoTone" two-tone-color="#f6d553" />
                    </template>

                     <template slot="cellphone" slot-scope="text,record">
                         <div>
                             {{record.cellphone}}
                         </div>
                        <!-- <a-popover :title="record.source_channel" v-if="record.tips">
                            <template slot="content">
                                <div style="white-space:pre-line;">{{record.tips}}</div>
                            </template>
                            <a-icon type="bulb" theme="twoTone" two-tone-color="#faad14" />
                        </a-popover> -->
                         <span style="font-size:10px;color: rgba(69, 90, 100, 0.6);" v-if="record.cellphone_geo">
                             ({{record.cellphone_geo.province}},{{record.cellphone_geo.op}})
                         </span>
                    </template>

                    <template slot="name" slot-scope="text, record">
                        <div style="">
                            <a style="display:block;max-width:90px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">{{ text }}</a>
                            <!-- <svg style="width: 16px;height: 16px;margin-left: 6px;" aria-hidden="true">
                                <use v-show="record.gender == 2" xlink:href="#icon-sex_woman"></use>
                                <use v-show="record.gender == 1" xlink:href="#icon-sex_man"></use>
                            </svg> -->
                         </div>
                         <span style="font-size:10px;color: rgba(69, 90, 100, 0.6);">
                            ({{record.left_amount+'课时'}})                             
                        </span>
                    </template>

                    <template slot="is_bind_qywx" slot-scope="text, record">
                        <svg style="width:16px;height: 16px;vertical-align: middle;" aria-hidden="true">
                            <use v-show="record.is_bind_qywx == 1" xlink:href="#icon-qiyeweixin"></use>
                            <use v-show="record.is_bind_qywx == 0" xlink:href="#icon-qiyeweixin-unbind"></use>
                        </svg>
                    </template>

                    <template slot="level" slot-scope="text, record">
                        <div @click.stop>
                            <!-- <a-dropdown placement="bottomCenter" :trigger="['click']">
                                <span style='cursor:pointer' @click="e => e.preventDefault()" v-if='text == null'>--</span>
                                <span v-if='text == item.value' @click="e => e.preventDefault()" class="follow-intentionLevels" :style="{color:text == item.value?'#fff':item.color,background:text == item.value?item.color:'#fff'}" v-for="item in intentionLevels" :key="item.value">{{item.label}}</span>
                                <a-menu slot="overlay" class='customer-menu'>
                                    <a-menu-item @click='handleLevel(item,record)' v-for="item in intentionLevels" :key='item.value'>
                                        <span class="follow-intentionLevels" :style="{color:text == item.value?'#fff':item.color,background:text == item.value?item.color:'#fff'}">{{item.label}}</span>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown> -->

                            <a-popover title="" placement="bottom">
                                <template slot="content">
                                <div>
                                    <span @click='handleLevel(item,record)' v-for="item in intentionLevels" :key='item.value' class="follow-intentionLevels" :style="{color:text == item.value?'#fff':item.color,background:text == item.value?item.color:'#fff'}">{{item.label}}</span>
                                </div>
                                </template>
                                <span style='cursor:pointer' @click="e => e.preventDefault()" v-if='text == null'>--</span>
                                <span v-if='text == item.value' @click="e => e.preventDefault()" class="follow-intentionLevels" :style="{color:text == item.value?'#fff':item.color,background:text == item.value?item.color:'#fff'}" v-for="item in intentionLevels" :key="item.value">{{item.label}}</span>
                            </a-popover>




                        </div>
                    </template>

                    <template slot="time" slot-scope="text">
                         <!-- <span v-if="text" v-html="timeHandle(moment(text).diff(moment(),'hour'))"></span> -->
                         <span v-if="text">
                             <a-badge :color="timeHandle(moment(text).diff(moment(),'hour')).color" :text="timeHandle(moment(text).diff(moment(),'hour')).text" />
                         </span>
                         <span v-else>--</span>
                    </template>

                    <template slot="promise" slot-scope="text">
                         <span v-if="text">{{moment(text).format("YYYY") == moment().format("YYYY")?moment(text).format("M月D日 HH:mm"):moment(text).format("YYYY年M月D日 HH:mm")}}</span>
                         <span v-else>--</span>
                    </template>

                    <template slot="attend" slot-scope="text">
                         <span v-if="text">{{moment(text).format("YYYY") == moment().format("YYYY")?moment(text).format("M月D日 HH:mm"):moment(text).format("YYYY年M月D日 HH:mm")}}</span>
                         <span v-else>--</span>
                    </template>
                    
                    <template slot="action" slot-scope="text,record">
                        <a href="javascript:;" @click="showFollowModal($event, record)">跟进</a>
                    </template>

                    <template slot="task_step" slot-scope="text,record">
                        
                        <div @click.stop>
                            <a-dropdown placement="bottomCenter" :trigger="['click']">
                                <a-tag style='cursor:pointer' v-for="item in dealStatus" :key='item.value' :color="item.color"  v-if="text==item.value">
                                    {{item.label}}
                                </a-tag>
                                <a-menu slot="overlay">
                                    <a-menu-item @click='handleDealStatus(item,record)' v-for="item in dealStatus" :key='item.value'>
                                        <a-tag style='cursor:pointer' :color="item.color">
                                            {{item.label}}
                                        </a-tag>
                                        <a-icon type="check" :style="{color: '#00cca2' }" v-if="text==item.value"/>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </div>
                        
                    </template>

                    <template slot="null_txt" slot-scope="text">
                         <span v-if="text" class="short_content">{{text}}</span>
                         <span v-else>--</span>
                    </template>

                    <template slot="deal_status" slot-scope="text,record">
                        <a-tag color="green" v-if="text==1">
                            在读学员
                        </a-tag>
                        <a-tag color="orange" v-if="text==0">
                            潜在学员
                        </a-tag>    
                    </template>
                    
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
        <followModal :authType="authType" :item="modalData" :leadsId='leadsId' isTab='1' v-if="followVisible && canRead"/>
        <uploadModal v-if="isUploadModal" isTab='1' />
        <editModal v-if="isEditModal" :isEdit='0' />
        <transfer :isTab='1' :item='selectedRecord' v-if="isTransfer"/>
        <startEvent :item='selectedRecord' v-if="isStartEvent"/>
        <auditionModal v-if="isAuditionModal" :item="selectedRecord" isTab="2" />
        <exportModal v-if="exportLoading" :exportData='exportData' :exportInfo='exportInfo' />
    </div>
</template>
<script>
const columns = [
  { title: '#', width:'35px', scopedSlots: { customRender: 'index' }, key: 'index',fixed: 'left', show_colspan: true,ellipsis: true},
  {title: '', width:'20px', dataIndex: 'customer_name', key: 'is_sta' ,fixed: 'left',scopedSlots: { customRender: 'star' }, show_colspan: true},
  {title: '客户名称',width:"100px", dataIndex: 'customer_name', key: '1' ,fixed: 'left', scopedSlots: { customRender: 'name' },ellipsis: true, show_colspan: true},
//   {title: '性别', width:'45px', dataIndex: 'gender', key: 'gender',align:"center" ,fixed: 'left'},
//   {title: '年龄', width:'45px', dataIndex: 'year', key: 'year',align:"center" ,fixed: 'left'},
  {title: '联系电话', width:'120px',dataIndex: 'cellphone', key: 'cellphone' ,fixed: 'left',scopedSlots: { customRender: 'cellphone' }, show_colspan: true},
  { title: '', width: '40px', dataIndex: 'is_bind_qywx', key: 'is_bind_qywx', fixed: 'left', scopedSlots: { customRender: 'is_bind_qywx' } ,show_sorter: true, show_colspan: true},
//   {title: '学员类型', width:'100px', dataIndex: 'deal_status', key: 'deal_status' , fixed: 'left',scopedSlots: { customRender: 'deal_status' },show_sorter: true, show_colspan: true},
//   {title: '跟进状态', width:'100px',dataIndex: 'task_step', key: 'task_step' , fixed: 'left',scopedSlots: { customRender: 'task_step' },show_sorter: true, show_colspan: true,align:"left",},
  {title: '流失时间', dataIndex: 'lost_time', key: 'lost_time', align:"center" , show_sorter: true, show_colspan: true,scopedSlots: { customRender: 'null_txt' }},
  {title: '最后跟进', dataIndex: 'recent_contact', key: 'recent_contact', show_sorter: true, show_colspan: true,scopedSlots: { customRender: 'null_txt' }},
  {title: '跟进内容', dataIndex: 'last_follow', key: 'last_follow' , show_colspan: true,scopedSlots: { customRender: 'null_txt' }},
  {title: '来源渠道', dataIndex: 'channel_id', key: '2',ellipsis: true ,show_sorter: true, show_colspan: true},
  {title: '归属校区', dataIndex: 'studio_id', key: 'studio_id',ellipsis: true ,show_sorter: true, show_colspan: true},
  {title: '负责人', dataIndex: 'course_consultant_id', key: 'course_consultant_id',show_sorter: true, ellipsis: true, show_colspan: true ,align:'center'},
  {title: '添加人', dataIndex: 'created_by', key: 'created_by' ,show_sorter: true, ellipsis: true, show_colspan: true, align:'center'},
  {title: '备注', dataIndex: 'remark', key: 'remark', ellipsis: true, show_colspan: true},
//   {title: '转化时间', dataIndex: 'created_at', key: 'created_at' ,show_sorter: true, show_colspan: true},
]

import uploadModal from '@/views/clientresource/modal/uploadModal'
import followModal from '@/views/clientresource/modal/followModal/index'
import transfer from '../clue/transfer'
import exportModal from '@/views/export/index'
import auditionModal from '../modal/followModal/auditionModal/index'
import tableMixins from '@/common/mixins/table'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import { timeHandle } from '@/utils/timeFormat'
import editModal from './editModal'
import startEvent from './startEvent'
import moment from 'moment'
import C_ITEMS from '@/utils/items'

export default {
    name:'customer',
    provide() {
        return {
            parent: this
        }
    },
    mixins: [ tableMixins, authority, ranges ],
    components:{
        editModal,
        uploadModal,
        followModal,
        exportModal,
        transfer,
        startEvent,
        auditionModal
    },
    data(){
        return{
            loading: false,
            visible: false,
            visibleF: false,
            showColumns:false,
            list:[],
            pageSizeOptions: ['10', '20', '30', '40', '100'],
            current: 1,
            columns,
            badgeCount:0,
            columnsList:[],
            selectedRowKeys:[],
            pageParams: {
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0
            },
            searchParams: {
                "page": 1,
                "per-page": 10,
                "search": {
                    is_sub:0
                },
                "sort": ''
            },
            searchDatas:{},
            modalData: {},
            isEditModal:false,
            isUploadModal:false,
            isAuditionModal:false,
            followVisible:false,
            exportLoading:false,
            leadsId:'',
            clickId:'',
            grades:[],
            studios:[],
            channels:[],
            channelsId:[],
            consultants:[],
            staffList:[],
            eventList:[],
            classCategorys:[],
            customerName:'',
            phoneNumber:undefined,
            studioId:undefined,
            isTransfer:false,
            isStartEvent:false,
            authType:['crm','customer'],
            isAttends: C_ITEMS.isAttendsF,
            intentionLevels:C_ITEMS.intentionLevelsF,
            studentTypes:C_ITEMS.studentTypes,
            dealStatus:C_ITEMS.dealStatus,
            paramsData:{
                start_date:'',
                end_date:'',
            },
            exportData:{},
            exportInfo:{
                url:'cCustomerExportAction',
                name:'客户列表导出'
            },
            customer_optionList:[
                    {label:'不限',value:''},
                    {label:'小于',value:'<'},
                    {label:'小于等于',value:'<='},
                    {label:'大于',value:'>'},
                    {label:'大于等于',value:'>='},
                ],
            customer_optionValue:'',
            customer_number:'',
        }
    },
    beforeCreate () {
        if(!this.$ls.get('is_sub')){
            this.$ls.set('is_sub', 0)
        }
    },
    created(){
        // this.$ls.remove('leads_columnsList')//该行代码2021-11-3可删除
        this.columnsList = this.columns
        this.columnsList.forEach(item=>{
            if(item.show_sorter){
                item.sorter = ()=>{}
                item.sortDirections= ['descend', 'ascend'];
            }
        })
        // this.getGrade()
        this.getStudio()
        this.getSourceChannel()
        this.getConsultant()
        this.getClassCategory()
        this.getStaff()
        this.getEventList()
    },
    methods:{
        moment,
        timeHandle,
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        async getList() {
            if(!this.canIndex){
                this.$message.warning('您暂无权限!!')
                return false
            }
            if(this.$route.query.start_date){
                this.searchParams.search.add_start_date = this.$route.query.start_date
                this.searchParams.search.add_end_date = this.$route.query.end_date
                this.searchDatas.add = [moment(this.$route.query.start_date),moment(this.$route.query.end_date)]
            }
            this.loading = true
            this.searchParams.search.is_sub = this.$ls.get('is_sub')
            await this.$store.dispatch('cCustomerLostAction', {data: this.searchParams})
            .then(res=>{
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            })
            .catch(err=>{
                this.loading = false
            })
            
        },
        async getGrade(){
            let res = await this.$store.dispatch('searchGetGradeTypeAction', {})
            this.grades = res.data
        },
        async getStudio() {
            let res = await this.$store.dispatch('searchBelongStudioAction', {})
            this.studios = res.data
        },
        async getSourceChannel(id) {
            let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
            this.channels = res.data
        },
        async getConsultant() {
            let res = await this.$store.dispatch('searchConsultantAction', {})
            this.consultants = res.data
        },
        async getClassCategory() {
            let res = await this.$store.dispatch('searchCourseTypeAction', {})
            this.classCategorys = res.data
        },
        async getStaff(val){
            let obj = {q:val}
            let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
            this.staffList = res.data
        },
        async getEventList(){
            await this.$store.dispatch('cFollowEventAction', {})
            .then(res=>{
                this.eventList = res.data
            })
        },
        handleSearch(val){
            this.getStaff(val)
        },
        showUploadModal(){
            this.isUploadModal = true
        },
        hideUploadModal(){
            this.isUploadModal = false
        },
        clickRow(record, index) {
            return {
                on: {
                    click: () => {
                        this.clickId = record.customer_id
                        this.showFollowModal(null, record)
                    }
                }
            }
        },
        rowClassName(record, index) {
            let className = "";
            if (record.customer_id == this.clickId) {
                className = "rowActive";
                this.$emit('change', this.clickId)
            }
            return className;
        },
        showFollowModal(e, item) {
            e && e.stopPropagation()
            this.modalData = item
            this.leadsId = item.customer_id
            this.followVisible = true
            return 
        },
        hideFollowModal(type) {
            if (type === 1) {
                this.getList()
            }
            this.followVisible = false
        },
        handleChange(pagination, filters, sorter){
            if(sorter.order){
                if(sorter.order === "ascend"){
                    this.searchParams['sort'] = `${sorter.field}`
                }else{
                    this.searchParams['sort'] = `-${sorter.field}`
                }
                }else{
                this.searchParams['sort'] = ''
                }
            this.getList();
        },
        showEditModal(){
            this.isEditModal = true
        },
        hideEditModal(val){
            if(val){
                this.getList()
            }
            this.isEditModal = false
        },
        showTransfer(){
            this.isTransfer = true
        },
        hideTransfer(num){
            if(num){
                this.selectedRowKeys = []
                this.getList()
            }
            this.isTransfer = false
        },
        toRenew(){
            let that = this
            this.$confirm({
                title: `您确定renew这些客户？`,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                zIndex: 4200,
                onOk() {
                return new Promise(async (resolve, reject) => {
                    await that.$store.dispatch('cCustomerRenewAction', { task_id: that.selectedRowKeys })
                    .then(res=>{
                        that.$message.success('操作成功~')
                        that.selectedRowKeys = []
                        that.getList()
                        resolve(res)
                    })
                    .catch(err=>{
                        that.$message.error('操作失败~')
                        reject(err)
                    })
                }).catch(error => console.log(error))
                }
            })
        },
        toEvent(){
            this.isStartEvent = true
        },
        hideStartEvent(num){
            if(num){
                this.selectedRowKeys = []
                this.getList()
            }
            this.isStartEvent = false
        },
        async toExport(){
            let exportData = {
                search:this.searchParams.search,
                sort:this.searchParams.sort
            }
            exportData.search.task_id = this.selectedRowKeys
            this.exportData = exportData
            this.exportLoading = true
        },
        hideExportModal(){
            this.searchParams.search.task_id = []
            this.selectedRowKeys = []
            this.exportLoading = false
        },
        toDel(){
            let that = this
            this.$confirm({
                title: '您确定删除这些客户吗?',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                zIndex: 4200,
                onOk() {
                return new Promise(async (resolve, reject) => {
                    let res = await that.$store.dispatch('cCustomerDeleteAction', {task_id:that.selectedRowKeys})
                    if (res) {
                    that.selectedRowKeys = []
                    that.$message.success('操作成功!')
                    that.getList()
                    resolve(res)
                    }
                }).catch(error => console.log(error))
                }
            })
        },
        toPool(){
            let that = this
            this.$confirm({
                title: '您确定把这些客户放入公海吗?',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                zIndex: 4200,
                onOk() {
                return new Promise(async (resolve, reject) => {
                    let res = await that.$store.dispatch('cCustomerPutinPoolAction', {task_id:that.selectedRowKeys})
                    if (res) {
                    that.selectedRowKeys = []
                    that.$message.success('操作成功!')
                    that.getList()
                    resolve(res)
                    }
                }).catch(error => console.log(error))
                }
            })
        },
        showAuditionModal(){
            this.isAuditionModal = true
        },
        hideAuditionModal(){
            this.isAuditionModal = false
        },
        handleIsSub(e){
            this.$ls.set('is_sub', e)
            this.searchParams.search.is_sub = e
            this.changeBadge()
        },
        reset() {
            this.badgeCount = 0
            this.channelsId = null
            this.searchDatas = {}
            this.searchParams.search = {is_sub:0}
        },
        closeSearch() {
            this.visible = false
        },
        handleTime(val,time,name){
            this.searchDatas[name] = val
            this.$forceUpdate()
            let start_date = name + '_start_date'
            let end_date = name + '_end_date'
            this.searchParams.search[start_date] = time[0]
            this.searchParams.search[end_date] = time[1]
            this.changeBadge()
        },
        handleChangeRadioStar(e){
            if(this.searchParams.search.is_star == e){
                this.searchParams.search.is_star = undefined
            }else{
                this.searchParams.search.is_star = e
            }
            this.searchList()
        },
        async handleDealStatus(item,record){
            await this.$store.dispatch('cCustomerTaskUpdateAction', {task_id:record.task_id,task_step:item.value})
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.getList()
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                })
            
        },
        async handleLevel(item,record){
            let params = {
                intention_level:item.value,
                task_id:record.task_id
            }
            let res = await this.$store.dispatch('cCustomerTaskUpdateAction', params)
            this.getList()
            this.$message.success('操作成功~')
        },
        async toTop(item){
            let that = this
            this.$confirm({
                title: '您确定取消客户置顶??',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                zIndex: 4200,
                onOk() {
                return new Promise(async (resolve, reject) => {
                    await that.$store.dispatch('cCustomerStarAction', {task_id:item.task_id})
                    .then(res=>{
                        that.$message.success('操作成功~')
                        that.getList()
                        resolve(res)
                    })
                    .catch(err=>{
                        that.$message.error('操作失败~')
                    })
                }).catch(error => console.log(error))
                }
            })
        },
        onChangeColspan(item){
            item.show_colspan = !item.show_colspan
            this.$ls.set('customer_columnsList', this.columns)
            let arr = []
            this.columns.forEach(item=>{
                if(item.show_colspan){
                    arr.push(item)
                }
            })
            this.columnsList = arr
        },
        onChangeCostWarn(){
            if(this.customer_optionValue && this.customer_number){
                this.searchParams.search.times = `${this.customer_optionValue} ${this.customer_number}`
            }else{
                this.searchParams.search.times = undefined
            }
            this.changeBadge()
        },
        changeBadge(){
            let obj = {...this.searchParams.search}
            delete obj.keyword
            delete obj.is_sub
            delete obj.sub_status
            delete obj.studio_id
            var _toString = Object.prototype.toString
            let sum = 0
            for(let i in obj){
                console.log(_toString.call(obj[i]).slice(8, -1))
                if(_toString.call(obj[i]).slice(8, -1) == 'Array' && obj[i].length >0){
                    sum++
                }
                if(_toString.call(obj[i]).slice(8, -1) == 'Number'){
                    sum++
                }
                if(_toString.call(obj[i]).slice(8, -1) == 'String' && obj[i]){
                    if(i == 'promise_start_date' || i == 'add_start_date' || i == 'attend_start_date' || i == 'next_start_date' || i == 'update_start_date' || i == 'distribute_start_date' || i == 'times'){
                        sum++
                    }
                }
            }
            this.badgeCount = sum
        },
    }
}
</script>
<style lang="scss">
  .rowActive{
    background:#e6fff6
  }
  .follow-intentionLevels{
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        border: 1px solid;
        margin: 0 5px;
        cursor: pointer;
    }
    .short_content{
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        display: -moz-box;
        -moz-line-clamp: 2;
        -moz-box-orient: vertical;

        overflow-wrap: break-word;
        word-break: break-all;
        white-space: normal;
        overflow: hidden;
    }
   .customer-menu{
       display:flex;
       .ant-dropdown-menu-item{
           padding:5px 3px;
       }
   }
</style>